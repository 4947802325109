<template>
  <div class="liff-auth-page">
    <div class="page__wrapper">
      <div class="auth-title">接下來，需要請您輸入您的<br><strong>會員編號</strong> 喔！</div>

      <div class="auth-form">
        <a-input
          v-model:value="formState.member_id"
          :disabled="submitted"
          class="form-input"
          placeholder="點此輸入會員編號"
          size="large"
        ></a-input>
      </div>

      <div class="auth-actions">
        <a-button
          class="action-btn"
          type="primary"
          @click="bindUser"
          :disabled="submitted"
        >輸入完畢</a-button>

        <a-button
          class="action-btn"
          outlined
          style="color:#1890FF; border-color:#1890FF;"
        >我忘記了我的會員編號</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { message } from 'ant-design-vue'
import liff from '@line/liff'
import api from '@/apis'

export default defineComponent({
  components: {},
  setup() {
    return {
      /* Antd */
      message
    }
  },
  async mounted() {
    await this.init();
    // await this.parseLineId();
  },
  data() {
    return {
      /* Loading */
      submitted: false,

      /* Liff */
      context: {},

      /* Form */
      formState: {
        member_id: ''
      }
    }
  },
  methods: {
    /* Router */
    toAuthConfirmPage() {
      this.$router.push({ name: 'LiffAuthConfirm' });
    },

    /* Liff */
    async init() {
      try {
        await liff.init({ liffId: process.env.VUE_APP_LIFF_BIND_USER });
        this.context = liff.getContext();
      } catch (err) {
        console.log(err.code, err.message);
      }
    },

    /* Api */
    // async parseLineId() {
    //   let line_id;
    //   if (process.env.VUE_APP_ENV == 'production') {
    //     line_id = this.context.userId;
    //   } else if (process.env.VUE_APP_ENV == 'development') {
    //     line_id = process.env.VUE_APP_DEV_LINE_ID;
    //   }
      
    //   try {
    //     await api.v1.line.parserLineId({
    //       line_id: line_id
    //     });

    //     console.log('user already binded,');
    //     this.$router.push({ name: 'LiffAuthConfirm' });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async bindUser() {
      console.log(this.formState)
      if (!this.formState.member_id) {
        message.error('請輸入會員編號');
        return
      }

      let line_id;
      if (process.env.VUE_APP_ENV == 'production') {
        line_id = this.context.userId;
      } else if (process.env.VUE_APP_ENV == 'development') {
        line_id = process.env.VUE_APP_DEV_LINE_ID;
      }

      try {
        await api.v1.line.userBind({
          member_id: this.formState.member_id,
          line_id: line_id
        });

        this.submitted = true;
        message.success('綁定成功，3秒後自動關閉視窗');
        setTimeout(() => {
          liff.closeWindow();
        }, 3000);
      } catch (error) {
        console.log(error);

        if (error.response.status == 304) {
          this.submitted = true;
          message.warning('您已完成綁定，3秒後自動關閉視窗');
          setTimeout(() => {
            liff.closeWindow();
          }, 3000);
        }

        if (error.response.status == 400) {
          message.error('綁定失敗，請確認您的會員編號是否輸入正確');
        }
      }
    }
  }
})
</script>

<style lang="scss">
.liff-auth-page {
  .page__wrapper {
    padding: 60px 30px;
  }

  .auth-title {
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 50px;
  }

  .auth-form {
    margin-bottom: 60px;

    .form-input {
      height: 58px;
      font-size: 1.8rem !important;
      line-height: 1.8rem !important;
      text-align: center;
      max-height: none;
    }
  }

  .auth-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .action-btn {
      font-size: 1.3rem;
      height: 55px;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 16px;
    }
  }
}
</style>